.sales {
	padding: 0px 0 60px 0;
	background-color: rgba($text, 0.05);

	a.sales-carousel__item {
		display: block;
		overflow:hidden;
		position: relative;
		color:#fff;
		z-index:2;
		&:hover {
			color:$accent;
		}
		img {
			width: 100%;
			transition: all 5s ease-out;
			&:hover {
				transform: scale(1.1);
			}
		}
		h3 {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index:2;
			padding: 15px;
			text-align: center;
			background-color: rgba($text, 0.4);
			margin: 0;
		}
	}
	.owl-nav {
		color:#fff;
		&:hover {
			color:$accent;
		}
		.owl-prev {
			position: absolute;
			left: 0px;
			bottom: 0px;
			padding: 15px 28px;
			background-color: rgba($text, 0.4);
			transition: all 0.5s ease-out;
			&:hover {
				padding-left: 40px;
				transition: all 0.5s ease-out;
			}
		}
		.owl-next {
			position: absolute;
			right: 0px;
			bottom: 0px;
			padding: 15px 28px;
			background-color: rgba($text, 0.1);
			transition: all 0.5s ease-out;
			&:hover {
				padding-right: 40px;
				transition: all 0.5s ease-out;
			}
		}
	}
	.fa {
		font-size: 2rem;
	}
}