.fasady {
	.js-fasady {
		padding-bottom: 40px;
		.owl-nav {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			.owl-next,
			.owl-prev {
				position: absolute;
				top: 35%;
				margin-top: -1rem;
				z-index: 1;
				color:rgba($text, 0.3);
				&:hover {
					color:$accent;
				}
				i {
					font-size: 2rem;
				}
			}
			.owl-prev {
				left: -15px;
			}
			.owl-next {
				right: -15px;
			}
		}
		.fasady__link {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 10px;
			img {
				border:1px solid rgba($text, 0.1);
				box-shadow: 0px 0px 10px 0px rgba($text, 0.1);
			}
			&:hover {
				text-decoration: none;
				img {
					border:1px solid rgba($accent, 0.5);
					box-shadow: 0px 0px 10px 0px rgba($accent, 0.5);
				}
			}
			.description {
				text-align: center;
				margin-top: 5px;
				color:$text;
				line-height: 1rem;
				span {
					font-size: 1.2rem;
					line-height: 1.2rem;
				}
				p {
					margin-top: 5px;
				}
			}
		}
	}
	.collapse {
		position: relative;
		width: 100%;
		margin-top: -50px;
		margin-bottom: 40px;
		padding: 20px 0 40px 0;
		.description {
			text-align: center;
			margin-top: 5px;
			color:$text;
			line-height: 1rem;
			span {
				font-size: 1.2rem;
				line-height: 1.2rem;
			}
			p {
				margin-top: 5px;
			}
		}
		img {
			display: block;
			height: auto;
			border: 1px solid #dee2e6;
			box-shadow: 0px 0px 10px 0px #dee2e6;
		}
		.close-f {
			position: absolute;
			top: 20px;
			right: 20px;
			color:$text;
			z-index: 999;
		}
	}
	.collapse.show {
		// display: block;
		@include phones {
			display: none;
		}
	}
	.collapsing {
		@include phones {
			display: none;
		}
	}
}
