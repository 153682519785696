a#top {
	width: 50px;
	height: 50px;
	overflow: hidden;
	z-index: 99;
	display: none;
	background-color: rgba($text, 0.6);
	position: fixed;
	bottom: 60px;
	right: 20px;
	&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			margin: 17px auto;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 15px solid $accent;
	}
	&:hover {
			background-color: $text;
	}
}