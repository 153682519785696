header {
	margin-top: 5px;
	margin-bottom: 15px;
	.bg-gray {
		position:relative;
		background-color:transparent;
		&:after {
			content:"";
			display: block;
			position:absolute;
			top: 0;
			right: -100%;
			bottom: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.05);
			z-index: -1;
			@include desktop {
				right: 0;
			}
		}
	}
}
.logo {
	display: block;
	margin-top: 5px;
	width: 140px;
	@include laptop {
		width: 100px;
	}
	// @include phones {
	// 	width: 100px;
	// }
	img {
		width: 100%;
	}
}
.menu-top {
	ul {
		display:flex;
		li {
			margin: 0 0 5px 0;
			white-space: nowrap;
			display: flex;
			align-items: center;
			&:after {
				content:"";
				display:block;
				background-color: rgba($text, 0.3);
				width:2px;
				height:2px;
				margin-left:0.7rem;
				margin-right:0.7rem;
				@include laptop {
					margin-left:0.2rem;
					margin-right:0.2rem;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			a {
				font-size:1.1rem;
				color: rgba($text, 0.5);
				text-decoration:underline;
				&:hover {
					text-decoration:none;
				}
				@include laptop {
					font-size:0.8rem;
				}
			}
		}
	}
	@include laptop {
		display: none;
	}
}
a.phone-top {
	color:$text;
	font-size: 1.5rem;
	font-weight: bold;
	// @include laptop {
	// 	position:absolute;
	// 	top:-1px;
	// 	right: 20px;
	// 	width: 100%;
	// 	white-space: nowrap;
	// 	text-align: right;
	// }
	// @include phonesm {
	// 	position: relative;
	// }
	@include phones {
		font-size: 1.1rem;
	}
}

.bg-gray {
	background-color: rgba($text, 0.05);
}

.menu-main {
	ul {
		display:flex;
		li {
			margin:13px 0;
			white-space: nowrap;
			display: flex;
			align-items: center;
			@include laptop {
				flex-direction: column;
				align-items: start;
			}
			&:after {
				content:"";
				display:block;
				background-color: rgba($text, 0.3);
				width:2px;
				height:1.2rem;
				margin-left:0.8rem;
				margin-right:0.8rem;
				@include laptop {
					display: none;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
			a {
				font-family:"FuturaBook";
				font-size:1.2rem;
				line-height: 1.2rem;
				color:$text;
				text-decoration:none;
				text-transform:uppercase;
				&:hover {
					text-decoration:underline;
				}
				@include desktop {
					font-size:1.0rem;
				}
			}
		}
	}
}

.callback {
	background-color: #fff;
	border:1px solid rgba($accent, 0.7);
	text-align: center;
	a {
		font-size:1.0rem;
		line-height: 1.0rem;
		color:rgba($text, 0.7);
		text-decoration: none;
		white-space: nowrap;
		display: block;
		padding: 7px 10px;
	}
	&:hover {
		background-color: rgba($accent, 0.7);
	}
	@include laptop {
		margin-bottom: 5px;
	}
}