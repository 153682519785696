.news {
	padding: 0 0 40px 0;
	.title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		&:after,
		&:before {
			content:"";
			display: inline-block;
			height: 1px;
			width: 100%;
			background-color: rgba($text, 0.3);
		}
		&:after {
			margin-left: 15px;
			margin-right: 5px;
		}
		&:before {
			margin-left: 5px;
			margin-right: 15px;
		}
		h2 {
			white-space:nowrap;
		}
	}
	a {
		position: relative;
		display: block;
		margin: 5px;
		img {
			width: 100%;
			height: 100%;
		}
		.description {
			position:absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba($text, 0.7);
			font-size: 1rem;
			line-height: 1rem;
			color:#fff;
			z-index: 2;
			p {
				text-align: center;
				padding: 10px;
				margin-bottom:0;
			}
		}
		&:hover {
			opacity:0.9;
		}
	}
	.col-lg-6 {
		a {
			position:relative;
			height: 500px;
			&:after {
				content:"";
				position:absolute;
				display: block;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba($text, 0.3);
				z-index: 1;
			}
			@include desktop {
				height: 400px;
			}
			@include laptop {
				height: 400px;
			}
			@include phones {
				height: auto;
			}
		}
	}
	.col-lg-3 {
		a {
			height: 245px;
			@include desktop {
				height: 195px;
			}
			@include laptop {
				height: 300px;
			}
			@include phones {
				height: auto;
			}
		}
	}
}

.show__all {
	display:flex;
	align-items: center;
	justify-content: center;
	// &:hover {
		// &:after {
		// 	margin-top: 2px;
		// }
	// }
	a {
		display: flex;
		align-items: center;
		font-size: 1.2rem;
		color:$text;
		}
		i {
			display: block;
			font-size: 1.5rem;
			margin-left: 10px;
			margin-bottom: 3px;
			transition: all 0.5s ease-out;
		}
		&:hover {
			i {
				margin-bottom: -3px;
				transition: all 0.5s ease-out;
			}
		}
	// &:after {
	// 	content:"";
	// 	display: block;
	// 	width: 40px;
	// 	height: 40px;
	// 	background: url("/assets/images/img/arrow-down.png") no-repeat center center;
	// 	background-size: contain;
	// }
}