.breadcrumb {
	margin: 0;
	padding: 0 20px;
	background-color: transparent;
	.active {
		color:$text;
	}
	a {
		color:$text;
		text-decoration:underline;
		&:hover {
			text-decoration:none;
		}
	}
}