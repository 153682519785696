.modal-content {
	border-radius: 0;
	h5 {
		width: 100%;
		text-align: center;
	}
	color:$text;
	span {
		color:$text;
	}
	.modal-body {
		background-color: #fff;
		color:$text;
		padding: 0 20px;
	}
}

.form-check {
	display: flex;
	align-items: baseline;
	text-align: left;
	label {
		text-transform: none;
		text-align: left;
		font-size: 0.85rem;
		a {
			color:$text;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
