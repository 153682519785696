// .menu-top {

// }
.menu-main {
	.navbar {
    padding: 0;
	}
	.dropdown .dropdown-menu {
		// display: block;
		// visibility: hidden;
    // opacity: 0;
    transition: all 5s ease;
	}
	.dropdown:hover > .dropdown-menu { 
		display: block;
		// visibility: visible;
		// opacity: 1;
		@include phones {
			display: none;
		}
	} 
	.dropdown-toggle::after {
    display: none;
	}
	.navbar-expand-lg .navbar-nav .nav-link {    
    padding: 0;
	}
	.dropdown-menu {
    border:0;
		border-radius: 0;
		margin-top: -1px;
	}
	.dropdown-submenu {
    position: relative;
	}
	.dropdown-submenu>.dropdown-menu {
			top: 0;
			left: 100%;
			margin-top: -6px;
			margin-left: -1px;
			border-radius: 0;
	}
	.dropdown-submenu:hover>.dropdown-menu {
			display: block;
	}
	.dropdown-submenu>a:after {
			display: block;
			content: " ";
			float: right;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			border-width: 5px 0 5px 5px;
			border-left-color: #ccc;
			margin-top: 5px;
			margin-right: -10px;
	}
	.dropdown-submenu:hover>a:after {
			border-left-color: #fff;
			@include phones {
				display: none;
			}
	}
	.dropdown-submenu.pull-left {
			float: none;
	}
	.dropdown-submenu.pull-left>.dropdown-menu {
			left: -100%;
			margin-left: 10px;
			border-radius: 0;
	}
	.dropdown-submenu:hover > a::after {
		border-left-color: $accent;
	}
	.dropdown-menu a {
		text-transform: none;
	}
}
