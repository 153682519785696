#KantryModal.modal,
#myModal.modal,
.myModal,
.KantryModal {
	padding-right: 0 !important;
	.modal-full {
		min-width: 100%;
		margin: 0;
	}
	.close {
		position: absolute;
		// top: 20px;
		top: -30px;
		right: 20px;
		@include tablets {
			top: -40px;
		}
	}
	.modal-full .modal-content {
		min-height: 100vh;
		padding-top: 50px;
		@include tablets {
			padding-top: 40px;
		}
	}
	
	.modal-header {
		border:none;
	}
	
	.modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;
		// background-color: #333;
		font-size: 1rem;
		// color:#fff;
		text-align: center;
		width: 100%;
		padding: 0;
		margin: 0;
		.container-fluid {
			margin: 0;
			padding: 0;
		}
		h5 {
			font-size: 2.25rem;
		}
		p {
			color:#9a9a9a;
			text-align: justify;
			padding: 10px 20%;
			font-size: 1.5rem;
			line-height: 1.5rem;
			@include desktop {
				padding: 10px 20%;
			}
			@include laptop {
				padding: 10px 10%;
			}
			@include tablets {
				padding: 10px 5%;
			}
		}
		.btn-outline-secondary {
			color: #9a9a9a;
			background-color: transparent;
			background-image: none;
			border-color: #fff;
			font-size: 1.3rem;
			padding: 10px 30px;
			&:hover {
				background-color: #9a9a9a;
				color:#fff;
			}
		}
	}
	.modal-footer {
		border:none;
	}
	
	.img-fluid {
		width: auto;
		// height: 100vh;
		@include tablets {
			height: auto;
		}
	}
	
	.close {
		float: right;
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 1;
		color: #fff;
		text-shadow: 0 1px 0 #fff;
		opacity: .5;
		z-index: 999;
		&:hover, &:focus {
			color: #fff;
			text-decoration: none;
			opacity: .75;
		}
	}
	
	.carousel-control-next-icon {
		background-image: url('');
	}
	.carousel-control-prev-icon {
		background-image: url('');
	}

	.carousel-control-prev, .carousel-control-next {
		font-size: 3rem;
		opacity: 1;
		&:hover {
			color:$accent;
		}
	}

	.carousel-indicators {
		position: absolute;
		// right: 0px;
		bottom: 8%;
		left: 2%;
		z-index: 15;
		display: flex;
		justify-content: center;
		padding-left: 0;
		margin-right: 15%;
		margin-left: 15%;
		list-style: none;
		flex-direction: row;
		align-items: center;
		// background-color: #333;
		border-radius: 10px;
		padding: 10px;
		// width: 40px;
		margin: 0;
		margin-left: -20px;
		margin-bottom: -50px;
		@include phones {
			margin-left: -30px;
		}
	}
	
	.carousel-indicators li {
		position: relative;
		flex: 0 1 auto;
		width: 10px;
		height: 10px;
		margin-right: 3px;
		margin-left: 3px;
		text-indent: -999px;
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 50%;
		margin: 5px;
		cursor: pointer;
		&:hover {
			background-color: #fff;
		}
	}
	.carousel-indicators li.active {
		background-color: #fff;
	}
}
