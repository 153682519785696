.text {
	margin-bottom: 20px;
	font-size: 1.2rem;
	text-align: justify;
}

.style {
	.style__link {
		// margin-bottom: 25px;
		width: 100%;
		height: 100%;
		overflow:hidden;
		padding: 15px;
		.description {
			display: flex;
			flex-direction:column;
			justify-content: center;
			align-items: center;
			position: absolute;
			background-color: rgba(#fff, 0.7);
			top: 3rem;
			bottom: 3rem;
			left: 3rem;
			right: 3rem;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			padding: 0.5rem 0.25rem;
			span {
				text-transform:uppercase;
				color:#fff;
				background-color: rgba(#000, 0.7);
				padding: 5px 15px;
				border-radius:10px;
				transition: all 0.2s ease-in-out;
				transition-delay: 0s;
			}
			p {
				color:$text;
				// font-weight: bolder;
				margin-top: 10px;
				margin-bottom: -100px;
				transition: all 0.2s ease-in-out;
				transition-delay: 0s;
				
				
			}
		}
		&:hover {
			.description {
				opacity: 1;
				top: 2rem;
				bottom: 2rem;
				left: 2rem;
				right: 2rem;
				transition: all 0.3s ease-in-out;
				span {
					transition: all 0.3s ease-in-out;
				}
				p {
					margin-bottom: 0px;
					transition: all 0.3s ease-in-out;
				}
			}
		}
		@include phones {
			opacity: 1;
			overflow: visible;
			.description {
				opacity: 1;
				top: 2rem;
				bottom: 2rem;
				left: 2rem;
				right: 2rem;
				transition: all 0.3s ease-in-out;
				span {
					transition: all 0.3s ease-in-out;
				}
				p {
					margin-bottom: 0px;
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}
	.active-kantry {
		.active-kantry-img {
			overflow:hidden;
			width: 100%;
			height: 100%;
		}
		img {
			transition: all 0.5s ease-out;
			&:hover {
				transform: scale(1.1);
			}
		}
		span {
			display: flex;
			flex-direction:column;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: 30px;
			left: 30px;
			margin-right: 40px;
			transition: all 0.3s ease-in-out;
			text-align: center;
			text-transform:uppercase;
			color:#fff;
			background-color: rgba(#000, 0.7);
			padding: 10px 20px;
			border-radius:10px;
			transition: all 0.2s ease-in-out;
			transition-delay: 0s;
			white-space: pre-wrap;
			border-radius: 0;
		}
	}

	a {
		position:relative;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	img {
		width: 100%;
		height: 100%;
	}
	
	ul {
		display: flex;
		justify-content: center;
		li {
			display: flex;
			align-items: center;
			&:after {
				content:"/";
				display:block;
				font-size: 1.2rem;
			}
			a {
				font-size: 1.2rem;
				color: rgba($text, 0.8);
				&:hover {
					text-decoration: underline;
				}
			}
		}
		li:last-child {
			&:after {
				display:none;
			}
		}
	}
	.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: rgba($text, 0.8);
		background-color: transparent;
		text-decoration: underline;
	}

	.js-nav-pills,
	.js-style-cantry {
		padding-left: 20px;
		padding-right: 19px;
		// margin-bottom: 30px;
		.nav-item {
			display: block;
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 60px;
				color:$text;
				border:1px solid rgba($text, 0.3);
				border-radius:0;
				// white-space: nowrap;
				text-align: center;
				&:hover,
				&:focus,
				&active {
					text-decoration: underline;
				}
			}
		}
		.owl-nav {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			.owl-prev,
			.owl-next {
				position:absolute;
				top:50%;
				margin-top: -1rem;
				font-size: 2rem;
				color:rgba($text, 0.3);
				z-index: 1;
				&:hover {
					color:$accent;
				}
			}
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}
	}
}


.style-kantry {
	.overflow-hidden {
		overflow: hidden;
	}
	.box-title {
		position: relative;
		left: -100px;
		background-color: rgba(#fff, 0.9);
		padding: 20px 30px;
		@include desktop {
			left: 0px;
		}
		@include phones {
			left: 0px;
		}
		span {
			font-size: 2.5rem;
			line-height: 2.5rem;
		}
		p {
			margin: 0;
		}
	}

	.card {
    position: relative;
    display: flex;
		flex-direction: column;
		justify-content: center;
    min-width: 0;
    border: none;
		border-radius: 0;
		h2 {
			text-align: center;
			button {
				width: 100%;
				font-size: 1.7rem;
				color:$text;
			}
		}
		p {
			font-size: 1.2rem;
			padding: 40px;
		}
	}

	.gallery {
		// padding: 50px;
		a {
			position: relative;
			display: block;
			width: 100%;
			height: 260px;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 20px;
				// left: 100%;
				left: 20px;
				right: 20px;
				bottom: 20px;
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				border-right: none;
				background-color: transparent;
				transition: all 0.3s ease-in-out;
				z-index: 1;
			}
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 20px;
				left: 20px;
				right: 20px;
				// bottom: 100%;
				bottom: 20px;
				border-left: 1px solid #fff;
				border-right: 1px solid #fff;
				transition: all 0.3s ease-in-out;
			}
			img {
				height: 100%;
				filter: brightness(70%)
			}
			i {
				// display: none;
				font-size: 1px;
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-top: -1rem;
				margin-left: -1rem;
				color: transparent;
			}
			&:hover {
				&:before {
					// left: 20px;
					left: 100%;
					transition: all 0.3s ease-in-out;
				}
				&:after {
					// bottom: 20px;
					bottom: 100%;
					transition: all 0.3s ease-in-out;
				}
				img {
					filter: brightness(40%)
				}
				i {
					font-size: 2rem;
					color:#fff;
					opacity: 0.6;
					z-index: 2;
					margin-top: -1rem;
					margin-left: -1rem;
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}
	.owl-nav {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		.owl-prev,
		.owl-next {
			position:absolute;
			top:50%;
			margin-top: -1.5rem;
			font-size: 3rem;
			color:rgba($text, 0.3);
			z-index: 1;
			&:hover {
				color:$accent;
			}
		}
		.owl-prev {
			left: 20px;
		}
		.owl-next {
			right: 20px;
		}
	}
}

.owl-stage {
	margin: auto;
}