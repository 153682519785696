.advantage {
	margin: 20px 0;
	img {
		display: block;
		margin: 20px auto;
		@include phones {
			margin-left: auto;
			margin-right: auto;
		}
	}
	span {
		display: block;
		font-size: 1.2rem;
		font-weight: bold;
		text-align: center;
		@include phones {
			text-align: center;
		}
	}
	p {
		@include phones {
			text-align: center;
		}
	}
}