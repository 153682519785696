footer {
	padding-top: 40px;
	background-color: rgba($text, 0.9);
	font-size: 1.1rem;
	color:#fff;
	text-transform:uppercase;
	@include desktop {
		font-size: 1.1rem;
	}
	@include laptop {
		font-size: 1.0rem;
	}
	ul {
		li {
			@include phones {
				text-align: center;
			}
		}
	}
	a {
		color:#fff;
		&:hover {
			color:$accent;
			text-decoration:none;
		}
	}
	.menu-bottom,
	.info-bottom {
		@include phones {
			display: none;
		}
	}
	.contacts {
		text-transform:none;
		line-height: 1.2rem;
		ul {
			li:first-child {
				text-transform:uppercase;
			}
			li:nth-child(even) {
				margin-top: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.social {
		ul {
			display: flex;
			justify-content: flex-end;
			margin-top: 10px;
			li {
				margin: 5px;
			}
			@include phones {
				justify-content: center;
			}
		}
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			background-color: transparent;
			padding: 10px 15px;
			border: 1px solid #404044;
			color:#404044;
			border-radius:50px;
			font-size: 0.9rem;
			&:hover {
				background: rgba(255, 255, 255, 0.14);
				border-color: rgba(255, 255, 255, 0);
				color:#fff;
			}
		}
	}
	.bottom {
		margin-top: 10px;
		background-color: rgba($text, 0.1);
		font-size: 1.0rem;
		text-transform:none;
		@include phones {
			font-size: 0.9rem;
		}
	}
	.copyright {
		@include phones {
			margin-top: 4px;
		}
	}
}