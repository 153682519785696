.news-list {
	.col-12 {
		padding: 10px;
		@include phones {
			padding: 5px;
		}
	}
	.news__link {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		display: block;
		color:$text;
		text-decoration: none;
		img {
			width: 100%;
			height: 100%;
			transition: all 0.5s ease-out;
		}
		.description {
			position: absolute;
			top:0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: rgba($text, 0.5);
			padding: 1rem;
			font-size: 1rem;
			line-height: 1rem;
			color:#fff;
			z-index: 2;
			h2 {
				font-size: 1.9rem;
				line-height: 1.9rem;
				text-align: center;
				@include desktop {
					font-size: 1.5rem;
					line-height: 1.5rem;
				}
				@include phones {
					font-size: 1.6rem;
					line-height: 1.6rem;
				}
			}
			p {
				text-align: justify;
				padding: 10px;
				margin-bottom:0;
				@include phones {
					display: none;
				}
			}
			button, a.description__link, div {
				// display: flex;
				// flex-direction: row;
				// justify-content: space-between;
				display: block;
				border: 1px solid #fff;
				color:#fff;
				// text-decoration: none;
				background-color: transparent;
				padding: 10px 20px;
				margin-top: 5px;
				margin-bottom: 5px;
				z-index: 4;
				cursor: pointer;
				span {
					display: inline-block;
				}
				i {
					display: inline-block;
					margin-left: 10px;
				}
				&:hover {
					background-color: rgba(#fff, 0.1);
				}
			}
			}
		}
		&:hover {
			// opacity:0.9;
			img {
				filter: contrast(40%);
				transition: all 0.5s ease-out;
			}
		}
	a.description__link {
		// display: flex;
		// flex-direction: row;
		// justify-content: space-between;
		display: block;
		border: 1px solid #fff;
		color:#fff;
		// text-decoration: none;
		background-color: transparent;
		padding: 10px 20px;
		margin-top: 5px;
		margin-bottom: 5px;
		z-index: 4;
		cursor: pointer;
		span {
			display: inline-block;
		}
		i {
			display: inline-block;
			margin-left: 10px;
		}
		&:hover {
			background-color: rgba(#fff, 0.1);
		}
	}
}

.gallery {
	&:hover {
		img {
			filter: contrast(70%);
		}
	}
}