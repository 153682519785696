
$default-font: "FuturaBookC", Helvetica, Arial, sans-serif;
$accent: #ffed00;
$text: #000;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$gutter: $grid-gutter-width;